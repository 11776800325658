<template>
    <div>
        <b-alert :show="alert.show" :variant="alert.variant" fade>
            <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
                alert.text
            }}</span>
        </b-alert>
        <!--begin::Advance Table Widget 10-->
        <div class="card card-custom gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                    <span class="card-label font-weight-bolder text-dark">{{
                        $t("PRODUCT.PRODUCTS")
                    }}</span>
                    <span
                        class="text-muted mt-3 font-weight-bold font-size-sm"
                        >{{
                            $tc(
                                "PRODUCT.SHOW_COUNT",
                                paginatedProducts.length,
                                {
                                    total: products.length,
                                }
                            )
                        }}</span
                    >
                </h3>
                <div class="card-toolbar">
                    <div class="card-toolbar">
                        <router-link
                            :to="{ name: 'product', params: { id: 'new' } }"
                        >
                            <b-button variant="primary">{{
                                $t("PRODUCT.NEW")
                            }}</b-button>
                        </router-link>
                    </div>
                    &nbsp;&nbsp;
                    <div class="card-toolbar">
                        <a
                            v-on:click="downloadCSVData()"
                            class="btn btn-secondary"
                        >
                            {{ $t("PRODUCT.EXPORT") }}
                        </a>
                    </div>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body py-0">
                <!--begin::Table-->
                <div class="table-responsive" v-if="products.length">
                    <table
                        class="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_4"
                    >
                        <thead>
                            <tr class="text-left">
                                <th class="pl-0" style="min-width: 120px">
                                    {{ $t("PRODUCT.NAME") }}
                                </th>
                                <th style="min-width: 110px">
                                    {{ $t("PRODUCT.BRAND_DIVISION") }}
                                </th>
                                <th style="min-width: 110px">
                                    {{ $t("PRODUCT.SKU") }}
                                </th>
                                <th style="min-width: 120px">
                                    {{ $t("PRODUCT.CATEGORY") }}
                                </th>
                                <th style="min-width: 120px">
                                    {{ $t("PRODUCT.SUB_CATEGORY") }}
                                </th>
                                <th
                                    class="pr-0 text-right"
                                    style="min-width: 160px"
                                >
                                    {{ $t("ACTIONS") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="product in paginatedProducts">
                                <tr v-bind:key="product.id">
                                    <td class="pl-0">
                                        <router-link
                                            :to="{
                                                name: 'product',
                                                params: { id: product.id },
                                            }"
                                            class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                                            >{{ product.name }}
                                        </router-link>
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ product.brand_division }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ product.sku }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ product.category }}</span
                                        >
                                    </td>
                                    <td>
                                        <span
                                            class="text-dark-75 font-weight-bold d-block font-size-lg"
                                            >{{ product.sub_category }}</span
                                        >
                                    </td>
                                    <td class="pr-0 text-right">
                                        <router-link
                                            :to="{
                                                name: 'product',
                                                params: {
                                                    id: product.id,
                                                    edit: true,
                                                },
                                            }"
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/Communication/Write.svg"
                                                    :title="$t('EDIT')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </router-link>
                                        <a
                                            v-on:click="
                                                confirmDelete(product.id)
                                            "
                                            class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        >
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary"
                                            >
                                                <!--begin::Svg Icon | path:assets/media/svg/icons/General/Trash.svg-->
                                                <inline-svg
                                                    src="media/svg/icons/General/Trash.svg"
                                                    :title="$t('DELETE')"
                                                ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                    <b-pagination
                        v-if="products.length > pagination.per_page"
                        class="pt-5"
                        v-model="pagination.current_page"
                        :total-rows="products.length"
                        :per-page="pagination.per_page"
                        align="right"
                    >
                    </b-pagination>
                </div>
                <!--end::Table-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Advance Table Widget 10-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"
import ApiService from "@/core/services/api.service"
import Swal from "sweetalert2"

export default {
    name: "products",
    data() {
        return {
            expanz: 0,
            csv: "",
            pronum: {
                anz: 0,
            },
            products: [],
            proexport: [],
            alert: {
                show: false,
                message: "",
                variant: "",
            },
            pagination: {
                current_page: 1,
                per_page: 20,
            },
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [])
        ApiService.get("products")
            .then(({ data }) => {
                this.products = data
            })
            .catch(() => {
                //Just keep it empty
            })
    },
    methods: {
        confirmDelete: function (id) {
            const product_index = this.products.findIndex(
                (product) => product.id === id
            )
            const product = this.products.find((product) => product.id === id)
            Swal.fire({
                title: this.$t("PRODUCT.DELETE_TITLE", { name: product.name }),
                text: this.$t("PRODUCT.DELETE_TEXT"),
                icon: "error",
                showCancelButton: true,
                cancelButtonText: this.$t("CANCEL"),
                confirmButtonText: this.$t("DELETE"),
                buttonsStyling: false,
                customClass: {
                    cancelButton: "btn btn-secondary",
                    confirmButton: "btn btn-danger",
                },
                heightAuto: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete("product/" + id)
                        .then(() => {
                            this.products.splice(product_index, 1)
                            this.showAlert(
                                this.$t("PRODUCT.DELETE_SUCCESS", {
                                    name: product.name,
                                }),
                                "success"
                            )
                        })
                        .catch(() => {
                            this.showAlert(
                                this.$t("PRODUCT.DELETE_FAILURE", {
                                    name: product.name,
                                }),
                                "danger"
                            )
                        })
                }
            })
        },
        showAlert(text, variant) {
            window.scrollTo(0, 0)
            this.alert.text = text
            this.alert.variant = variant
            this.alert.show = true
            setTimeout(() => (this.alert.show = false), 5000)
        },

        getPage: function (nr, pag, anz) {
            ApiService.get("product/export?fooid=" + nr * pag + "&").then(
                ({ data }) => {
                    this.proexport = data
                    this.proexport.forEach((row) => {
                        this.csv +=
                            row.name +
                            ";" +
                            row.brand_division +
                            ";" +
                            row.sku +
                            ";" +
                            row.category +
                            ";" +
                            row.sub_category +
                            ";" +
                            row.further_sub_category +
                            ";" +
                            row.description_1 +
                            ";" +
                            row.description_2 +
                            ";" +
                            row.description_3 +
                            ";" +
                            row.gender +
                            ";" +
                            row.fedas_pck +
                            ";" +
                            row.color_name +
                            ";" +
                            row.color_code +
                            ";" +
                            row.color_weight +
                            ";" +
                            row.color_year +
                            ";" +
                            row.color_origin +
                            ";" +
                            row.color_image +
                            ";" +
                            row.color_url +
                            ";" +
                            row.color_customs_nr +
                            ";" +
                            row.washing +
                            ";" +
                            row.bleaching +
                            ";" +
                            row.drying +
                            ";" +
                            row.ironing +
                            ";" +
                            row.professional_cleaning +
                            ";" +
                            row.matstring +
                            ";" +
                            row.size_name +
                            ";" +
                            row.size_ean +
                            ";" +
                            row.size_upc +
                            ";" +
                            row.size_rrp

                        this.csv += "\n"
                    })

                    this.expanz = this.expanz + 1
                    //console.log(this.expanz + "  " + nr + " " + anz)
                    if (this.expanz == anz) {
                        const anchor = document.createElement("a")
                        anchor.href =
                            "data:text/csv;charset=utf-8," +
                            encodeURIComponent(this.csv)
                        anchor.target = "_blank"
                        anchor.download = "PRODUCTS.csv"
                        anchor.click()
                    }
                }
            )
        },

        downloadCSVData: async function () {
            await ApiService.get("product/export_count/").then(({ data }) => {
                this.pronum = data

                this.csv =
                    "name;brand_division;sku;category;sub_category;further_sub_category;description_1;description_2;description_3;gender;fedas_pck;colors.name;colors.code;colors.weight;colors.year;colors.origin;colors.image;colors.url;colors.custom_nr;"
                this.csv =
                    this.csv +
                    "colors.laundry.washing;colors.laundry.bleaching;colors.laundry.drying;colors.laundry.ironing;colors.laundry.professional_cleaning;colors.components;colors.sizes.name;colors.sizes.ean;colors.sizes.upc;colors.sizes.rrp"
                this.csv = this.csv + "\n"
                this.expanz = 0
                let pa = 200

                let anz = Math.ceil(this.pronum / pa)

                for (let i = 0; i < anz; i++) {
                    this.getPage(i, pa, anz)
                }
            })
        },

        downresult: function () {
            const anchor = document.createElement("a")
            anchor.href =
                "data:text/csv;charset=utf-8," + encodeURIComponent(this.csv)
            anchor.target = "_blank"
            anchor.download = "PRODUCTS.csv"
            anchor.click()
        },
    },
    computed: {
        paginatedProducts() {
            const start =
                    (this.pagination.current_page - 1) *
                    this.pagination.per_page,
                end = start + this.pagination.per_page
            return this.products.slice(start, end)
        },
    },
}
</script>
